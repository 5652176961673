<template>
  <div class="checkout">
    <div v-if="!orderComplete" class="checkout__wrapper">
      <div class="checkout__main" v-if="!isAppointment">
        <SfSteps :active="currentStep" :steps="steps" @change="changeStep">
          <SfStep name="Choose Mode">
            <CheckoutSelectMode @next="nextStep()" />
          </SfStep>
          <SfStep name="Enter Details">
            <CheckoutDetailsMode @next="nextStep()" />
          </SfStep>
          <SfStep name="Place Order">
            <CheckoutReview
              @next="nextStep()"
              @order-completed="setOrderCompleted"
            />
          </SfStep>
        </SfSteps>
      </div>
      <div class="checkout__appointment" v-if="isAppointment">
        <CheckoutReview
          v-if="isAppointment"
          @order-completed="setOrderCompleted"
        />
      </div>
    </div>
    <OrderSuccess
      v-if="
        orderComplete &&
        (!providerConfig || providerConfig.providerName !== 'upi-manual')
      "
      class="order-success"
      :order-id="orderId"
      :success-message="store.orderConfig.messageAfterSuccess || 'Order Placed'"
    />
    <OrderSuccessUpi
      v-if="
        orderComplete &&
        providerConfig &&
        providerConfig.providerName === 'upi-manual'
      "
      class="order-success"
      :order-id="orderId"
      :order="order"
      :success-message="store.orderConfig.messageAfterSuccess || 'Order Placed'"
      :upi-config="providerConfig"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from "vuex";
import { notifications } from "@lib/modules/cart/helpers";
import { required, minLength, email } from "vuelidate/lib/validators";
import CheckoutSelectMode from "@theme/components/organisms/checkout-select-mode.vue";
import CheckoutDetailsMode from "@theme/components/organisms/checkout-details-mode.vue";
import CheckoutReview from "@theme/components/organisms/checkout-review.vue";

import { SfSteps } from "@lib/components";

import OrderSuccess from "@theme/components/Catalog/OrderSuccess";
import OrderSuccessUpi from "@theme/components/Catalog/OrderSuccessUpi";

export default {
  name: "Checkout",
  components: {
    CheckoutSelectMode,
    CheckoutDetailsMode,
    CheckoutReview,
    SfSteps,
    OrderSuccess,
    OrderSuccessUpi,
  },
  data() {
    return {
      orderComplete: false,
      hasMultipleModes: true,
      currentStep: 0,
      order: null,
      providerConfig: null,
    };
  },
  computed: {
    ...mapGetters({
      store: "store",
      storeId: "storeId",
      productsInCart: "cart/getCartItems",
      deliveryMode: "checkout/getDeliveryMode",
      dineInDetails: "checkout/getDineInDetails",
      orderId: "checkout/getOrderId",
    }),
    selectedDineInSpaceId() {
      return this.dineInDetails ? this.dineInDetails.spaceId : null;
    },
    selectedDineInValue() {
      return this.dineInDetails ? this.dineInDetails.value : null;
    },
    isAppointment() {
      return (
        this.store.appointmentConfig && this.store.appointmentConfig.enabled
      );
    },
    steps() {
      if (this.isAppointment) {
        return ["Review and Book Appointment"];
      } else {
        return ["Choose Mode", "Enter Details", "Place Order"];
      }
    },
  },
  watch: {
    productsInCart: {
      immediate: true,
      handler(val) {
        if (val.length === 0 && !this.orderComplete) {
          this.toCatalog();
        }
      },
    },
  },
  methods: {
    ...mapActions({
      notify: "notification/spawnNotification",
    }),
    nextStep() {
      this.currentStep++;
    },
    changeStep(index) {
      if (this.currentStep > index) {
        this.currentStep = index;
      }
    },
    toCatalog() {
      this.$router.push({ name: "catalog" });
    },
    setOrderCompleted({ order, providerConfig }) {
      this.orderComplete = true;
      this.order = order;
      this.providerConfig = providerConfig;
      this.$store.dispatch("cart/clear", { sync: true });
    },
  },
  created() {
    this.$store.commit("checkout/onStart");
  },
};
</script>
<style lang="scss">
@import "~@lib/styles/helpers/breakpoints";

.checkout {
  .sf-button {
    border-radius: 0.375rem;
  }

  box-sizing: border-box;
  @include for-desktop {
    padding: 0 var(--spacer-sm);
    max-width: 976px;
    margin: 0 auto;
  }

  &__wrapper {
    --steps-content-padding: var(--spacer-sm) var(--spacer-sm) 5rem
      var(--spacer-sm);
    @include for-desktop {
      --steps-content-padding: var(--spacer-xl) 0;
      display: flex;
    }
  }

  &__main {
    @include for-desktop {
      flex: 1;
      padding: var(--spacer-xl) 0;
    }
  }

  &__aside {
    @include for-desktop {
      flex: 0 0 26.8125rem;
      margin: 0 0 0 var(--spacer-xl);
    }
    &-order {
      box-sizing: border-box;
      width: 100%;
      box-shadow: 0px 4px 11px rgba(var(--c-dark-base), 0.1);
      background: var(--c-light);
      padding: var(--spacer-xl) calc(var(--spacer-lg) * 2);
    }
  }

  .card {
    margin: var(--spacer-base) 0;
  }

  &__appointment {
    padding: var(--spacer-lg) var(--spacer-base);
    @include for-desktop {
      flex: 1;
      padding: var(--spacer-xl) 0;
    }
  }
}
</style>
