<template>
  <div class="bg order-success-upi">
    <div class="center">
      <span class="center__success">
        <font-awesome-icon
          :icon="['fas', 'check']"
          size="2x"
          style="margin-top: 9px"
        />
      </span>
      <h2 class="card__msg">{{ successMessage }}</h2>
      <div class="card__body">
        <div class="card__recipient-info">
          <p class="card__order-number">
            Your Order Number: <b>{{ order.bill }}</b>
          </p>
        </div>
      </div>
      <div class="upi-manual">
        <div class="upi-manual__link">
          <a :href="upiLink">
            <SfButton class="color-primary" @click.native="openUpiApp">
              <!-- <span v-if="linkNavigateTimerSeconds">
                Opening UPI in {{ linkNavigateTimerSeconds }} seconds...
              </span>
              <span v-else> Open UPI </span> -->
              Make UPI Payment
            </SfButton>
          </a>
        </div>
        <h4>
          If above link does not work, please send
          {{ order.totalAmount | price }} to the UPI address
          <b>{{ upiConfig.account }}</b>
          <font-awesome-icon
            :icon="['far', 'clone']"
            title="copy UPI ID"
            size="xs"
            class="copy-upi"
            @click="copyUpiId"
          />
          or scan the code below
        </h4>
        <h4>
          Once paid, please send a screenshot or a transaction ID to us via
          Whatsapp or Email (links below)
        </h4>

        <QrCode
          class="qr-image"
          v-if="upiLink"
          :value="upiLink"
          :color="{ dark: '#1f1365', light: '#ffffff' }"
          errorCorrectionLevel="L"
          :scale="4"
        />
        <div class="confirm-via__wrapper">
          <span class="confirm-via">Confirm Via</span>
          <SfButton
            class="color-primary"
            @click.native="openWhatsappWithConfirmation"
          >
            <font-awesome-icon :icon="['fab', 'whatsapp']" size="lg" />
            Whatsapp
          </SfButton>
          <SfButton
            class="color-primary"
            @click.native="openEmailWithConfirmation"
          >
            <font-awesome-icon :icon="['far', 'envelope']" size="lg" /> Email
          </SfButton>
        </div>
      </div>

      <SfButton
        class="sf-button--full-width color-primary cart-action"
        @click.native="startShopping"
      >
        {{ $t("Back to shopping") }}
      </SfButton>
    </div>
  </div>
</template>

<script>
import { SfButton } from "@lib/components";
import QrCode from "vue-qrcode";
import { mapGetters, mapActions } from "vuex";
import { notifications } from "@lib/modules/cart/helpers";

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    var msg = successful ? "successful" : "unsuccessful";
    console.log("Fallback: Copying text command was " + msg);
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
}
function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    () => {
      console.log("Async: Copying to clipboard was successful!");
    },
    (err) => {
      console.error("Async: Could not copy text: ", err);
    }
  );
}

function formatForUpi(link) {
  return link.replace(/\s/g, "+");
}

export default {
  components: {
    SfButton,
    QrCode,
  },
  data() {
    return {
      showDetails: true,
      linkNavigateTimerSeconds: 5,
    };
  },
  computed: {
    ...mapGetters({
      store: "store",
    }),
    upiLink() {
      return formatForUpi(
        `upi://pay?pa=${this.upiConfig.account}&pn=${this.upiConfig.upiName}&tr=${this.order.bill}&tn=payment+for+order+${this.order.bill}&am=${this.order.totalAmount}`
      );
    },
  },
  props: {
    successMessage: {
      type: String,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
    upiConfig: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      notify: "notification/spawnNotification",
    }),
    startShopping() {
      this.$router.push({ name: "home" });
    },
    openUpiApp() {
      window.location.replace(this.upiLink);
    },
    copyUpiId() {
      copyTextToClipboard(this.upiConfig.account);
      let successNotfication = notifications.createNotification({
        type: "success",
        message: "Copied",
      });
      this.notify(successNotfication);
    },
    openWhatsappWithConfirmation() {
      let messages = [
        `Order #${this.order.bill}: UPI Payment confirmation, by ${this.order.order.personalDetails.fullName}`,
        "",
        "Screenshot/TransactionId:",
        "<Upload your screenshot or enter Transaction ID here>",
      ];
      let messageString = `${encodeURIComponent(messages.join("\n"))}`;
      window.location.replace(
        `https://api.whatsapp.com/send?phone=${this.store.contact.whatsappNumber}&text=${messageString}`
      );
    },
    openEmailWithConfirmation() {
      let subject = `Order #${this.order.bill}: UPI Payment confirmation, by ${this.order.order.personalDetails.fullName}`;
      let body = `Attached Transaction ID or UPI Payment screenshot for your reference`;
      let mailLink = encodeURI(
        `mailto:${this.store.contact.email}?subject=${subject}&body=${body}`
      );
      window.open(mailLink, "_blank");
    },
  },
  created() {
    const self = this;
    // const interval = setInterval(() => {
    //   if (self.linkNavigateTimerSeconds === 0) {
    //     self.openUpiApp();
    //     clearInterval(interval);
    //   } else {
    //     self.linkNavigateTimerSeconds--;
    //   }
    // }, 1000);
  },
};
</script>

<style lang="scss">
@import "~@lib/styles/helpers/breakpoints";

.order-success-upi {
  .confirm-via__wrapper {
    font-size: 0.875rem;

    .sf-button {
      --button-display: inline-block;
      --button-padding: var(--spacer-xs) var(--spacer-sm);
      --button-text-transform: none;
      margin: 0.25rem 0.5rem;
      font-size: 0.875rem;
      margin-right: 0;
    }

    .sf-button {
      border-radius: 0.375rem;
    }
  }

  .upi-manual {
    line-height: 1.75rem;

    &__link {
      .sf-button {
        margin: 0 auto;
      }
    }
    .copy-upi {
      margin: 0 0px 6px 5px;
      cursor: pointer;
    }

    h4 {
      font-weight: var(--font-medium);
    }
  }

  .upi-manual__link .sf-button {
    margin: 0 auto;
  }

  .bg {
    background-color: var(--c-white);
    overflow: hidden;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 40px;
    font-family: "Raleway sans-serif !important";
    color: var(--c-black);
    height: 100vh;
    width: 100%;
  }

  .card__msg {
    font-family: "Raleway", "sans-serif";
  }

  .cart-action {
    margin-top: 3rem;
  }

  .center {
    max-width: 440px;
    margin: 0 auto;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 40px 30px 25px 30px;
    text-align: center;
    position: relative;

    &__success {
      display: block;
      margin: 0 auto;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      background-color: #60c878;
      border: 5px solid white;
      color: white;
    }

    &__success i {
      color: white;
      line-height: 100px;
      font-size: 45px;
    }

    &__msg {
      text-transform: uppercase;
      color: #55585b;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 5px;
    }

    &__submsg {
      color: #959a9e;
      font-size: 16px;
      font-weight: 400;
      margin-top: 0px;
    }

    &__body {
      background-color: #f8f6f6;
      border-radius: 4px;
      width: 100%;
      margin-top: 30px;
      float: left;
      box-sizing: border-box;
      padding: 30px;
    }

    &__avatar {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      display: inline-block;
      margin-right: 10px;
      position: relative;
      top: 7px;
    }

    &__recipient-info {
      display: inline-block;
    }

    &__recipient {
      color: #232528;
      text-align: left;
      margin-bottom: 5px;
      font-weight: 600;
    }

    &__email {
      color: #838890;
      text-align: left;
      margin-top: 0px;
    }

    &__price {
      color: #232528;
      font-size: 70px;
      margin-top: 25px;
      margin-bottom: 30px;

      span {
        font-size: 60%;
      }
    }

    &__method {
      color: #d3cece;
      text-transform: uppercase;
      text-align: left;
      font-size: 11px;
      margin-bottom: 5px;
    }

    &__payment {
      background-color: white;
      border-radius: 4px;
      width: 100%;
      height: 100px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__credit-card {
      width: 50px;
      display: inline-block;
      margin-right: 15px;
    }

    &__card-details {
      display: inline-block;
      text-align: left;
    }

    &__card-type {
      text-transform: uppercase;
      color: #232528;
      font-weight: 600;
      font-size: 12px;
      margin-bottom: 3px;
    }

    &__card-number {
      color: #838890;
      font-size: 12px;
      margin-top: 0px;
    }

    &__tags {
      clear: both;
      padding-top: 15px;
    }

    &__tag {
      text-transform: uppercase;
      background-color: #f8f6f6;
      box-sizing: border-box;
      padding: 3px 5px;
      border-radius: 3px;
      font-size: 10px;
      color: #d3cece;
    }
  }
}
</style>
