<template>
  <transition name="fade">
    <div class="my-account-shipping-details">
      <SfTabs
        v-if="editAddress"
        key="edit-address"
        :open-tab="1"
        class="tab-orphan"
      >
        <SfTab :title="$t('Change the address')">
          <p class="message">
            {{ $t("Keep your addresses and contact details updated.") }}
          </p>
          <div class="form">
            <SfInput
              v-model="editedAddress.fullName"
              name="fullname"
              :label="$t('Full Name')"
              required
              :valid="!$v.editedAddress.fullName.$error"
              :error-message="
                !$v.editedAddress.fullName.required
                  ? $t('Field is required.')
                  : $t('Name must have at least 2 letters.')
              "
              class="form__element form__element--half"
            />
            <SfInput
              v-model="editedAddress.streetName"
              name="streetName"
              :label="$t('Street Name')"
              required
              :valid="!$v.editedAddress.streetName.$error"
              :error-message="$t('Field is required.')"
              class="form__element"
            />
            <SfInput
              v-model="editedAddress.apartment"
              name="apartment"
              :label="$t('House/Apartment number')"
              required
              :valid="!$v.editedAddress.apartment.$error"
              :error-message="$t('Field is required.')"
              class="form__element"
            />
            <SfInput
              v-model="editedAddress.city"
              name="city"
              :label="$t('City')"
              required
              :valid="!$v.editedAddress.city.$error"
              :error-message="$t('Field is required.')"
              class="form__element form__element--half"
            />
            <SfInput
              v-model="editedAddress.state"
              name="state"
              :label="$t('State/Province')"
              class="form__element form__element--half form__element--half-even"
            />
            <SfInput
              v-model="editedAddress.zipCode"
              name="zipCode"
              :label="$t('Zip-code')"
              required
              :valid="!$v.editedAddress.zipCode.$error"
              :error-message="
                !$v.editedAddress.zipCode.required
                  ? $t('Field is required.')
                  : $t('Zip-code must have at least 3 letters.')
              "
              class="form__element form__element--half"
            />
            <SfSelect
              v-model="editedAddress.countryName"
              :selected="editedAddress.countryName"
              name="country"
              :label="$t('Country')"
              required
              :valid="!$v.editedAddress.countryName.$error"
              :error-message="$t('Field is required.')"
              class="sf-select--underlined form__select form__element form__element--half form__select form__element--half-even"
            >
              <SfSelectOption
                v-for="country in countries"
                :key="country"
                :value="country"
              >
                {{ country }}
              </SfSelectOption>
            </SfSelect>
            <SfInput
              v-model="editedAddress.phone"
              name="phone"
              :label="$t('Phone number')"
              class="form__element"
            />
            <SfButton class="action-button" @click="updateAddress">
              {{ $t("Update the address") }}
            </SfButton>
          </div>
        </SfTab>
      </SfTabs>
      <SfTabs v-else key="address-list" :open-tab="1" class="tab-orphan">
        <SfTab :title="$t('Shipping details')">
          <p class="message">
            {{
              $t(
                "Manage all the shipping addresses you want (work place, home address ...) This way you won't have to enter the shipping address manually with each order."
              )
            }}
          </p>
          <transition-group tag="div" name="fade" class="shipping-list">
            <div
              v-for="address in addresses"
              :key="address.id"
              class="shipping"
            >
              <div class="shipping__content">
                <p class="shipping__address">
                  <span class="shipping__client-name">{{
                    address.fullName
                  }}</span
                  ><br />
                  {{ address.streetName }} {{ address.apartment }}<br />
                  {{ address.city }}, {{ address.state }},
                  {{ address.zipCode || address.postcode }}
                  <br />
                  {{ getCountryName(address.countryCode) }}
                </p>
                <p class="shipping__address">
                  {{ address.phone }}
                </p>
              </div>
              <div class="shipping__actions">
                <SfIcon
                  icon="cross"
                  color="gray"
                  size="14px"
                  role="button"
                  class="mobile-only"
                  @click="deleteAddress(address.id)"
                />
                <SfButton @click="changeAddress(address)">
                  {{ $t("Change") }}
                </SfButton>
                <SfButton
                  class="shipping__button-delete desktop-only"
                  @click="deleteAddress(address.id)"
                >
                  {{ $t("Delete") }}
                </SfButton>
              </div>
            </div>
          </transition-group>
          <SfButton class="action-button" @click="changeAddress({})">
            {{ $t("Add new address") }}
          </SfButton>
        </SfTab>
      </SfTabs>
    </div>
  </transition>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import { unicodeAlpha, unicodeAlphaNum } from "@lib/utility/helpers/validators";
import { SfTabs, SfInput, SfButton, SfSelect, SfIcon } from "@lib/components";
import CountryCodes from "@lib/utility/countryCodes";
import { mapGetters } from "vuex";

export default {
  name: "MyAccountShippingDetails",
  components: { SfTabs, SfInput, SfButton, SfSelect, SfIcon },
  data() {
    return {
      editAddress: false,
      editedAddress: {
        id: null,
        fullName: "",
        streetName: "",
        apartment: "",
        city: "",
        state: "",
        zipCode: "",
        countryName: "",
        phone: "",
      },
      countries: Object.keys(CountryCodes)
        .map((x) => CountryCodes[x])
        .sort(),
    };
  },
  computed: {
    ...mapGetters({
      countryCode: "user/countryCode",
    }),
    addresses() {
      return this.$store.state.user.addresses;
    },
    defaultCountryName() {
      if (this.countryCode && CountryCodes[this.countryCode]) {
        return CountryCodes[this.countryCode];
      }
      return null;
    },
  },
  watch: {
    defaultCountry: {
      immediate: true,
      handler: function (val) {
        if (!val) return;
        this.editedAddress.countryName = val;
      },
    },
  },
  methods: {
    changeAddress(address) {
      this.clearFields();
      this.$v.$reset();
      this.editedAddress.id = address.id;
      this.editedAddress.fullName = address.fullName;
      this.editedAddress.streetName = address.streetName;
      this.editedAddress.apartment = address.apartment;
      this.editedAddress.city = address.city;
      this.editedAddress.state = address.state;
      this.editedAddress.zipCode = address.zipCode || address.postcode;
      this.editedAddress.countryName =
        CountryCodes[address.countryCode] || this.defaultCountryName;
      this.editedAddress.phone = address.phone;
      this.editAddress = true;
    },
    clearFields() {
      this.editedAddress.id = null;
      this.editedAddress.fullName = "";
      this.editedAddress.streetName = "";
      this.editedAddress.apartment = "";
      this.editedAddress.city = "";
      this.editedAddress.state = "";
      this.editedAddress.zipCode = "";
      this.editedAddress.countryName = this.defaultCountryName || "";
      this.editedAddress.phone = "";
    },
    getCountryName(code) {
      return CountryCodes[code];
    },
    updateAddress() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$store.dispatch("notification/spawnNotification", {
          type: "danger",
          message: this.$t("Please fix the validation errors"),
          action1: { label: this.$t("OK") },
        });
        return;
      }

      let addressToUpdate = {
        id: this.editedAddress.id || null,
        fullName: this.editedAddress.fullName,
        streetName: this.editedAddress.streetName,
        apartment: this.editedAddress.apartment,
        city: this.editedAddress.city,
        state: this.editedAddress.state,
        countryCode: Object.keys(CountryCodes).find(
          (key) => CountryCodes[key] === this.editedAddress.countryName
        ),
        zipCode: this.editedAddress.zipCode,
        phone: this.editedAddress.phone || null,
      };

      this.$store.dispatch("user/updateAddress", addressToUpdate).then(() => {
        this.clearFields();
        this.editAddress = false;
      });
    },
    deleteAddress(addressId) {
      this.$store.dispatch("user/deleteAddress", addressId);
    },
  },
  validations: {
    editedAddress: {
      fullName: {
        required,
        minLength: minLength(2),
        unicodeAlpha,
      },
      streetName: {
        required,
        unicodeAlphaNum,
      },
      apartment: {
        required,
        unicodeAlphaNum,
      },
      city: {
        required,
        unicodeAlpha,
      },
      zipCode: {
        required,
        minLength: minLength(3),
      },
      countryName: {
        required,
      },
    },
  },
  mounted() {
    console.log(1);
    if (this.countryCode && CountryCodes[this.countryCode]) {
      console.log(2);
      this.editedAddress.countryName = CountryCodes[this.countryCode];
    }
  },
};
</script>

<style lang="scss">
@import "~@lib/styles/helpers/breakpoints";

.my-account-shipping-details {
  .shipping-list {
    margin: 0 0 var(--spacer-base) 0;
  }
  .shipping {
    display: flex;
    padding: var(--spacer-base) 0;
    border: 1px solid var(--c-light);
    border-width: 1px 0 0 0;
    &:last-child {
      border-width: 1px 0 1px 0;
    }
    &__content {
      flex: 1;
      color: var(--c-text);
    }
    &__actions {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      @include for-desktop {
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
      }
    }
    &__button-delete {
      --button-background: var(--c-light);
      --button-color: var(--c-dark-variant);
      &:hover {
        --button-background: var(--_c-light-primary);
      }
      @include for-desktop {
        margin: 0 0 0 var(--spacer-base);
      }
    }
    &__address {
      margin: 0 0 var(--spacer-base) 0;
      &:last-child {
        margin: 0;
      }
    }
  }
  .tab-orphan {
    @include for-mobile {
      --tabs-content-border-width: 0;
      --tabs-title-display: none;
      --tabs-content-padding: 0;
    }
  }
  .form {
    @include for-desktop {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    &__element {
      margin: 0 0 var(--spacer-base) 0;
      @include for-desktop {
        flex: 0 0 100%;
      }
      &--half {
        @include for-desktop {
          flex: 1 1 50%;
        }
        &-even {
          @include for-desktop {
            padding: 0 0 0 var(--spacer-lg);
          }
        }
      }
    }
    &__select {
      padding-bottom: calc(var(--font-xs) * 1.2);
    }

    @include for-mobile {
      .sf-overlay,
      .sf-select__dropdown {
        transform: translate3d(100%, 0, 0);
      }
    }
  }
  .message {
    margin: 0 0 var(--spacer-base) 0;
  }
  .action-button {
    --button-width: 100%;
    margin-bottom: 3rem;
    @include for-desktop {
      --button-width: auto;
    }
  }
}
</style>
