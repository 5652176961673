var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"o-my-account-orders-history"},[_c('SfTabs',{attrs:{"open-tab":1}},[_c('SfTab',{attrs:{"title":_vm.$t('My orders')}},[(!_vm.activeOrder)?[_c('p',{staticClass:"message"},[_vm._v(" "+_vm._s(_vm.$t( "Check the details and status of your orders in the online store." ))+" ")]),(_vm.orders.length === 0)?_c('div',{staticClass:"no-orders"},[_c('p',{staticClass:"no-orders__title"},[_vm._v(" "+_vm._s(_vm.$t("You currently have no orders"))+" ")]),_c('SfButton',{staticClass:"no-orders__button",nativeOn:{"click":function($event){return _vm.startShopping($event)}}},[_vm._v(" "+_vm._s(_vm.$t("Start shopping"))+" ")])],1):_c('SfTable',{staticClass:"orders"},[_c('SfTableHeading',[_vm._l((_vm.tableHeaders),function(tableHeader){return _c('SfTableHeader',{key:tableHeader},[_vm._v(" "+_vm._s(_vm.$t(tableHeader))+" ")])}),_c('SfTableHeader',{staticClass:"orders__element--right"})],2),_vm._l((_vm.orders),function(order){return _c('SfTableRow',{key:order.order_id},[_vm._l((order),function(data,key){return _c('SfTableData',{key:key},[(key === 'status')?[_c('span',{class:{
                    'text-success': data === 'Complete',
                    'text-danger': data === 'Canceled' || data === 'Closed',
                    'text-warning':
                      data !== 'Complete' &&
                      data !== 'Canceled' &&
                      data !== 'Closed',
                  }},[_vm._v(_vm._s(data))])]:[_vm._v(" "+_vm._s(data)+" ")]],2)}),_c('SfTableData',{staticClass:"orders__view orders__element--right"},[_c('SfButton',{staticClass:"sf-button--text color-secondary",nativeOn:{"click":function($event){return _vm.setActiveOrder(order)}}},[_vm._v(" "+_vm._s(_vm.$t("VIEW"))+" ")])],1)],2)})],2)]:[_c('OMyAccountOrderDetails',{attrs:{"order":_vm.activeOrder},on:{"close":function($event){return _vm.setActiveOrder(null)}}})]],2)],1),_c('div',{staticClass:"p10"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }