<template>
  <div class="account-profile">
    <SfTabs :open-tab="1">
      <SfTab :title="$t('Personal data')">
        <div class="m-update-personal-data">
          <div class="profile-item-edit mb4">
            <span class="profile-item-label">Email</span>
            <span class="profile-item-value">{{ email }}</span>
            <SfButton class="sf-button--pure profile-item" @click="addEmail">
              <span v-if="email">
                <font-awesome-icon :icon="['fas', 'pencil-alt']" />
              </span>
              <span v-else
                >Add
                <font-awesome-icon :icon="['fas', 'plus']" />
              </span>
            </SfButton>
          </div>
          <div class="profile-item-edit">
            <span class="profile-item-label">Full Name</span>
            <span class="profile-item-value">{{ fullName }}</span>
            <SfButton
              class="sf-button--pure profile-item"
              @click="updateFullName"
            >
              <span v-if="fullName">
                <font-awesome-icon :icon="['fas', 'pencil-alt']" />
              </span>
              <span v-else
                >Add <font-awesome-icon :icon="['fas', 'plus']"
              /></span>
            </SfButton>
          </div>
          <div class="profile-item-edit">
            <span class="profile-item-label">Phone Number</span>
            <span class="profile-item-value">{{ phoneNumber }}</span>
            <SfButton
              class="sf-button--pure profile-item"
              @click="verifyPhoneNumber"
            >
              <span v-if="phoneNumber">
                <font-awesome-icon :icon="['fas', 'pencil-alt']" />
              </span>
              <span v-else
                >Add <font-awesome-icon :icon="['fas', 'plus']"
              /></span>
            </SfButton>
          </div>

          <!-- <p class="notice">
            {{
              $t(
                "At Brand name, we attach great importance to privacy issues and are committed to protecting the personal data of our users. Learn more about how we care and use your personal data in the"
              )
            }}
            <a href="/privacy">{{ $t("Privacy Policy") }}</a
            >.
          </p> -->
        </div>
      </SfTab>
    </SfTabs>
  </div>
</template>

<script>
import { SfTabs, SfInput, SfButton, PhoneInput } from "@lib/components";
import { required, minLength, email } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import { ModalList } from "@theme/store/ui/modals";

export default {
  name: "OMyAccountProfile",
  components: {
    SfTabs,
    SfButton,
  },
  data() {
    return {
      fullName: "",
      email: "",
    };
  },
  computed: {
    ...mapGetters({
      phoneNumber: "user/phoneNumber",
    }),
  },
  methods: {
    ...mapActions("ui", {
      openModal: "openModal",
    }),
    updateFullName() {
      this.openModal({
        name: ModalList.UpdateFullName,
      });
    },
    verifyPhoneNumber() {
      this.openModal({
        name: ModalList.VerifyPhoneNumber,
      });
    },
    addEmail() {
      this.openModal({
        name: ModalList.AddEmail,
      });
    },
    updatePersonalData() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$store.dispatch("notification/spawnNotification", {
          type: "danger",
          message: this.$t("Please fix the validation errors"),
          action1: { label: this.$t("OK") },
        });
        return;
      }
      let updatedProfile = JSON.parse(
        JSON.stringify(this.$store.state.user.current)
      );
      updatedProfile.fullName = this.fullName;
      updatedProfile.email = this.email;
      updatedProfile.phone = this.phone.number;
      updatedProfile.phoneDialCode = this.phone ? this.phone.dialCode : "";
      this.$bus.$emit("myAccount-before-updateUser", updatedProfile);
    },
  },
  beforeMount() {
    // current user may not be available yet in beforeMount hook so vuex watcher is needed
    const unsubscribeFromStoreWatch = this.$store.watch(
      (state) => state.user.current,
      (currentUser) => {
        if (currentUser) {
          this.fullName = currentUser.name;
          this.email = currentUser.email;
        }
      },
      { immediate: true }
    );
    this.$once("hook:beforeDestroy", unsubscribeFromStoreWatch);
  },
  validations: {
    fullName: {
      required,
      minLength: minLength(2),
    },
    email: {
      required,
      email,
    },
    phone: {
      required,
      complex(value) {
        return this.isPhoneValid;
      },
    },
  },
};
</script>

<style lang="scss">
.account-profile {
  .profile-item-edit {
    margin: 16px 0;
  }

  .profile-item-value {
    font-size: 1.3rem;
    padding: 0 0 0 1rem;
  }

  .profile-item {
    display: inline-block;
    --button-padding: 0 0.5rem;
  }
}
</style>
