<template>
  <div class="checkout-mode">
    <div class="checkout-mode__wrapper card">
      <div class="checkout-mode__delivery">
        <SfRadio
          v-if="store.orderConfig.pickupEnabled"
          label="Pickup from store"
          name="delivery-mode"
          value="pickup"
          :selected="deliveryMode"
          @input="setDeliveryMode"
        />
        <SfRadio
          v-if="store.orderConfig.deliveryEnabled"
          label="Get it delivered to you"
          description=""
          name="delivery-mode"
          value="ship"
          :selected="deliveryMode"
          @input="setDeliveryMode"
        />
      </div>
      <div class="checkout-mode__dine">
        <div class="checkout-mode__dine-space-select" v-if="dineInEnabled">
          <div
            class="checkout-mode__dine-spaces"
            v-if="!store.dineSpaces || !store.dineSpaces.length"
          >
            <SfRadio
              label="Dine In"
              name="delivery-mode"
              value="default"
              :selected="deliveryMode"
              @input="setDeliveryMode"
            />
          </div>
          <div v-else>
            <SfHeading
              title="Dine-In"
              :level="3"
              class="sf-heading--left sf-heading--no-underline title"
            />
            <div class="dine-in-spaces">
              <SfRadio
                v-for="dineSpace in store.dineSpaces"
                :key="dineSpace.id"
                :label="dineSpace.title"
                description=""
                name="delivery-mode"
                :value="dineSpace.uid"
                :selected="selectedDineInSpaceId"
                @input="setDineInSpaceId"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="checkout-mode__details">
        <div
          class="checkout-mode__dine-value card"
          v-if="deliveryMode === 'dine'"
        >
          <SfInput
            :value="selectedDineInValue"
            :label="getSpaceInputLabel()"
            name="dineInValue"
            class="checkout-mode__dine-value__input form__element"
            :required="true"
            :valid="!$v.dineInDetails.value.$error"
            :error-message="$t('Field is required')"
            @input="onDineInValueInput"
            @blur="$v.dineInDetails.value.$touch()"
          />
        </div>
        <div
          class="checkout-mode__ship-slot card"
          v-if="deliveryMode === 'ship' && deliverySlots.length"
        >
          <div class="checkout-mode__ship-slot__label">
            Select a delivery slot
          </div>
          <div class="checkout-mode__ship-slot__select">
            <div class="inline-group">
              <div
                v-for="slot in deliverySlots"
                :key="slot.id"
                @click="selectDeliverySlot(slot.id)"
                :class="[
                  'inline-group-item',
                  shippingSlotId === slot.id ? 'active' : '',
                  slot.disabled ? 'disabled' : '',
                ]"
              >
                {{ slot.label }}
              </div>
            </div>
          </div>
          <div
            v-if="$v.selectedSlotId.$error"
            class="checkout-mode__ship-slot__error-message"
          >
            Please select a slot ID
          </div>
        </div>
      </div>
      <SfButton
        class="checkout-mode__next sf-button--full-width actions__button"
        @click="next"
        >Proceed
      </SfButton>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";

import { SfHeading, SfButton, SfInput, SfRadio } from "@lib/components";
import config from "@config/config";
import { notifications } from "@lib/modules/cart/helpers";

export default {
  components: {
    SfHeading,
    SfButton,
    SfInput,
    SfRadio,
  },
  validations() {
    const validations = {};
    if (this.deliveryMode === "dine") {
      const needsSpace = this.store.dineSpaces && this.store.dineSpaces.length;
      validations.dineInDetails = {
        value: {
          value: {
            complex(value) {
              return !!this.selectedDineInValue;
            },
          },
        },
      };
      if (this.store.dineSpaces && this.store.dineSpaces.length) {
        validations.dineInDetails.spaceId = {
          value: {
            complex() {
              return !!this.selectedDineInSpaceId;
            },
          },
        };
      }
    } else if (this.deliveryMode === "ship" && this.deliverySlots.length) {
      validations.selectedSlotId = {
        value: {
          complex() {
            return !!this.shippingSlotId;
          },
        },
      };
    }
    return validations;
  },
  computed: {
    ...mapGetters({
      store: "store",
      storeId: "storeId",
      deliveryMode: "checkout/getDeliveryMode",
      dineInDetails: "checkout/getDineInDetails",
      shippingSlotId: "checkout/getShippingSlotId",
    }),
    dineInEnabled() {
      return this.store.dineInConfig && this.store.dineInConfig.dineInEnabled;
    },
    selectedDineInSpaceId() {
      return this.dineInDetails ? this.dineInDetails.spaceId : null;
    },
    selectedDineInValue() {
      return this.dineInDetails ? this.dineInDetails.value : null;
    },
    deliverySlots() {
      if (
        !this.store ||
        !this.store.orderConfig ||
        !this.store.orderConfig.deliverySlots
      ) {
        return [];
      }
      return this.store.orderConfig.deliverySlots.filter((x) => !x.disabled);
    },
  },
  methods: {
    ...mapActions({
      notify: "notification/spawnNotification",
    }),
    setDeliveryMode(mode) {
      this.$store.commit("checkout/setDeliveryMode", mode);
    },
    setDineInSpaceId(spaceId) {
      this.$store.commit("checkout/setDineInSpaceId", spaceId);
      this.checkoutStage = "enterDeliveryDetails";
    },
    selectDeliverySlot(slotId) {
      this.$store.commit("checkout/setShippingSlotId", slotId);
      this.$v.selectedSlotId.$touch();
    },
    getSpaceInputLabel() {
      if (this.store.dineSpaces && this.selectedDineInSpaceId) {
        let space = this.store.dineSpaces.find(
          (x) => x.uid === this.selectedDineInSpaceId
        );
        return space && space.inputLabel ? space.inputLabel : "Table Number:";
      }
      return "Table Number:";
    },
    onDineInValueInput(e) {
      this.$store.commit("checkout/setDineInValue", e);
    },
    next() {
      if (!this.deliveryMode) {
        this.notify(
          notifications.createNotification({
            type: "danger",
            message: "Please select a mode",
          })
        );
        return;
      }

      if (this.$v.$invalid) {
        this.$v.$touch();
        this.notify(
          notifications.createNotification({
            type: "danger",
            message: "Please enter the required details",
          })
        );
        return;
      }
      this.$emit("next");
    },
  },
};
</script>

<style lang="scss">
.checkout-mode {
  .inline-group {
    overflow: hidden;
    padding-top: 2rem 0;

    &-item {
      cursor: pointer;
      border-radius: 50px;
      float: left;
      margin: 0.25rem;
      position: relative;
      display: block;
      padding: 0.5rem 1rem;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.125);

      &.active {
        color: #fff;
        background-color: var(--c-primary);
        border-color: var(--c-primary);
      }

      &.disabled {
        color: #6c757d;
        pointer-events: none;
        background-color: #fff;
      }
    }
  }

  &__ship-slot {
    &__label {
      margin: var(--spacer-xs) 0;
    }

    &__error-message {
      margin: var(--spacer-xs) 0;
      color: var(--input-error-message-color, var(--c-danger));
      height: calc(var(--font-xs) * 1.2);
      font: var(
        --input-error-message-font,
        var(--input-error-message-font-weight, var(--font-medium))
          var(--input-error-message-font-size, var(--font-xs)) /
          var(--input-error-message-font-line-height, 1.2)
          var(--input-error-message-font-family, var(--font-family-secondary))
      );
    }
  }
}
</style>
